<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="success" size="small" @click="exportList" :disabled="!btn.export"
          ><i class="el-icon-top-right"></i> 导出</el-button
        >
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getDeptsNow()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
          ><i class="el-icon-refresh-right"></i> 刷新</el-button
        >
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'stff_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @row-dblclick="dbClickJp"
        @getTableData="getStffsList"
      >
      </search-table>
      <!--      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getDeptsNow()">
          <el-row>
            <el-col :md="6">
              <el-form-item label="员工编号：">
                <el-input size="small" v-model.trim="searchForm.stff_no" clearable placeholder="请填写员工编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="公司抬头：">
                <el-select
                  v-model="searchForm.cptt_id"
                  filterable
                  placeholder="请选择公司抬头"
                  clearable
                  @visible-change="getCpttV1($event)"
                  size="small"
                >
                  <el-option v-for="item in cpttGroupOpt" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="员工岗位：">
                <el-input v-model.trim="searchForm.stff_job" clearable placeholder="请填写员工岗位" size="small" />
              </el-form-item>
            </el-col>
            <el-col :md="6">
              &lt;!&ndash; <el-form-item label="在职状态："> &ndash;&gt;
              <el-form-item label="在职状态:">
                <div class="block vg_mr_32">
                  <el-select v-model="searchForm.leave_time" placeholder="请选择员工在职状态" clearable size="small">
                    <el-option label="在职" :value="0"></el-option>
                    <el-option label="离职" :value="1"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="6">
              <el-form-item label="员工姓名：">
                <el-input size="small" v-model.trim="searchForm.stff_name" clearable placeholder="请填写员工姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="部门名称：">
                <el-select v-model="searchForm.dept_id" placeholder="请选择部门" clearable @visible-change="getDeptV1($event)" size="small">
                  <el-option v-for="item in deptGroupOpt" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="小组名称：">
                <el-select v-model="searchForm.dept_team_id" placeholder="请选择小组" clearable @visible-change="getDeptTeams" size="small">
                  <el-option
                    v-for="item in deptTeamsGroupOpt"
                    :key="item.dept_team_id"
                    :label="item.dept_team_name"
                    :value="item.dept_team_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="入职时间：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getDeptsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="success" size="small" @click="exportList" :disabled="!btn.export"
          ><i class="el-icon-top-right"></i> 导出</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            :data="tableData"
            @selection-change="handleSelectionChange"
            @row-dblclick="dbClickJp"
            border
            style="width: 100%"
            v-loading="loading"
            class="vg_cursor"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="员工编号" prop="stff_no" width="120" />
            <el-table-column label="公司名称" prop="cptt_name" />
            <el-table-column label="部门名称" prop="dept_name" width="120" />
            <el-table-column label="小组名称" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.dept_team_name">{{ scope.row.dept_team_name }}</div>
                <div v-else>暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="员工岗位" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.stff_job">{{ scope.row.stff_job }}</div>
                <div v-else>暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="在职状态" prop="stff_leave_time" :formatter="formatDateLeft" width="120" />
            <el-table-column label="入职时间" prop="stff_enter_time" :formatter="formatDate" />
            <el-table-column label="员工姓名" prop="stff_name" width="120" />
            <el-table-column label="身份证号" prop="stff_identity">
              <template slot-scope="scope">
                <span v-if="scope.row.stff_identity">
                  {{ scope.row.stff_identity }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="员工司龄" prop="stff_enter_time" :formatter="formatDateSen" width="120" />
            <el-table-column label="聘用类型" prop="stff_labr_cont" :formatter="formatEmpType" width="120" />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
        </el-col>
      </el-row>-->
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import { dataAPI } from '@api/modules/data';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/SystemManagement/StffManage/stff';
export default {
  name: 'StffList.vue',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      tableProperties: tableProperties,
      loadFlag: false,
      cpttGroupOpt: [],
      deptGroupOpt: [],
      deptTeamsGroupOpt: [],
      stffJobGroupOpt: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loading: true,
      excelUrl: '',
      excelName: ''
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/stff_add' || from.path === '/stff_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getStffsList();
    },
    // 获取员工信息列表
    async getStffsList() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      await getNoCatch(stffAPI.getStffs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 获取cpttId
    getCpttV1(flag) {
      if (flag === true && this.cpttGroupOpt.length === 0) {
        get(cpttAPI.getAllCpttsV1).then(res => {
          if (res.data.code === 0) {
            this.cpttGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1).then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
          }
        });
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag) {
      if (flag === true && this.deptTeamsGroupOpt.length === 0) {
        get(deptAPI.getAllDeptTeams).then(res => {
          if (res.data.code === 0) {
            this.deptTeamsGroupOpt = res.data.data;
          }
        });
      }
    },
    //获取员工岗位
    getStffJob(flag) {
      if (flag === true && this.stffJobGroupOpt.length === 0) {
        get(dataAPI.getALLPosis).then(res => {
          if (res.data.code === 0) {
            this.stffJobGroupOpt = res.data.data;
          }
        });
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.stff_enter_time);
    },
    // 员工离职
    formatDateLeft(row) {
      if (row.stff_leave_time === 0) {
        return '在职';
      } else {
        return this.helper.toTimeDay(row.stff_leave_time) + '离职';
      }
    },
    // 员工司龄
    formatDateSen(row) {
      if (row.stff_enter_time) {
        return this.helper.toSeniority(row.stff_enter_time, row.stff_leave_time);
      }
    },
    // 查询
    getDeptsNow() {
      this.getStffsList();
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 双击编辑
    dbClickJp(row) {
      this.jump('/stff_edit', {
        perm_id: this.$route.query.perm_id,
        form_id: row.stff_id
      });
    },
    // 新增
    addRow() {
      this.jump('/stff_add', { perm_id: this.$route.query.perm_id });
    },
    //删除提示
    mBox() {
      if (this.multiSelection.length > 0) {
        this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.delRow();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
            this.$refs.multiTable.clearSelection();
          });
      } else {
        this.$message({
          message: '请选择至少一条',
          type: 'warning'
        });
        this.dialogFormVisible = false;
      }
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.stff_id);
      });
      this.$confirm('是否删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        post(stffAPI.deletStffByIds, { stff_id_list: ids })
          .then(res => {
            if (res.data.code === 0) {
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({ message: mg, type: tp });
              if (ids.length === this.tableData.length) {
                if (this.currentPage > 1) {
                  this.currentPage = this.currentPage - 1;
                  this.$refs.pubPagination.currentPage = this.currentPage;
                }
              }
              this.initData();
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          });
      });
    },
    //导出
    exportList() {
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      get(stffAPI.exportStffListV1, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.excelUrl = this.helper.megPath(res.data.data.url);
            this.excelName = `员工信息列表${helper.getDateTime()}.xlsx`;
            this.helper.downloadItemC(this.excelUrl, this.excelName);
            this.$message({ message: '导出成功', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
