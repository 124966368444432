import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import helper from '@assets/js/helper';

export const formatEmpType = row => {
  switch (row) {
    case 5:
      return '试用';
    case 1:
      return '返聘';
    case 2:
      return '实习';
    case 3:
      return '劳务';
    case 4:
      return '正式';
    case 6:
      return '兼职';
    case 7:
      return '无需';
    default:
      return '无生效合同';
  }
};
export const formatDateSen = row => {
  if (row.stff_enter_time) {
    return helper.toSeniority(row.stff_enter_time, row.stff_leave_time);
  }
};
export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '员工编号', prop: 'stff_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司名称', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '部门名称', prop: 'dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '在职状态',
    prop: 'leave_time',
    itemType: 'select',
    options: [
      { value: 0, label: '在职' },
      { value: 1, label: '离职' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    needOtherColumn: true,
    formatter: val => (val.stff_leave_time === 0 ? '在职' : '离职' + getDateNoTime(val.stff_leave_time, true))
  },
  {
    label: '入职时间',
    prop: 'stff_enter_time',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '员工姓名', prop: 'stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '身份证号', prop: 'stff_identity', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '员工司龄',
    prop: 'stff_enter_time',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    needOtherColumn: true,
    formatter: row => formatDateSen(row)
  },
  {
    label: '聘用类型',
    prop: 'stff_labr_cont',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => formatEmpType(val)
  }
];
